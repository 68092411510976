// ** React Imports
import { lazy, Suspense } from "react"

// ** Utility function for logging errors
const logError = (message, error) => {
  console.error(message, error) // Replace with a logging service if needed
}

// Lazy loading components with error handling
const loadComponent = (component) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {component.catch((error) => {
        logError("Error loading component", error)
        return <div>Error loading component</div>
      })}
    </Suspense>
  )
}

const LoginCover = loadComponent(
  lazy(() => import("../../views/pages/authentication/Login"))
)
const RegisterBasic = loadComponent(
  lazy(() => import("../../views/pages/authentication/RegisterCover"))
)
const ForgotPasswordBasic = loadComponent(
  lazy(() => import("../../views/pages/authentication/ForgotPasswordBasic"))
)
const ResetPasswordCover = loadComponent(
  lazy(() => import("../../views/pages/authentication/ResetPasswordCover"))
)
const TwoStepsBasic = loadComponent(
  lazy(() => import("../../views/pages/authentication/TwoStepsCover"))
)
const AccountSetup = loadComponent(
  lazy(() => import("../../views/pages/authentication/AccountSetup"))
)

const AuthenticationRoutes = [
  {
    path: "/login",
    element: LoginCover,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/two-step",
    element: TwoStepsBasic,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/register",
    element: RegisterBasic,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/forgot-password",
    element: ForgotPasswordBasic,
    layout: "BlankLayout",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/reset-password",
    element: ResetPasswordCover,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/setup-account/:id",
    element: AccountSetup,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  }
]

export default AuthenticationRoutes
